import styled from "styled-components";
import {themeGet} from '@styled-system/theme-get'
import {device} from '../../../theme'
 
export const FeaturePostWrapper = styled.div `
    padding-top: 0;
    padding-bottom: 50px;
    .feature-post-slider{
        padding-bottom: 45px;
    }
    display: none;
    ${device.medium}{
        display: block;
        margin-top: -250px;
    }
    ${device.large}{
        margin-top: -300px;
    }
    ${device.xlarge}{
        margin-top: -445px;
    }
`;